import { getAssociatedFields } from '../../requiredDetails/InterventionSide';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { REQUIRED_DETAILS_FOR_SURGERY } from '../../../../userCustomization/featureNames';

export default ({ code }) => {
  const canAddThisRequiredDetailAccordingToSpecialtyFlag = getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false);
  const surgeryCode = supportedSurgeryCodes.get(code);

  if (canAddThisRequiredDetailAccordingToSpecialtyFlag === false || surgeryCode === undefined) {
    return [];
  }

  const canBeBothSides = surgeryCode === IntervensionSideType.CAN_BE_BOTH_SIDE;

  return [getAssociatedFields({ canBeBothSides })];
};

/* eslint-disable no-shadow, no-unused-vars */
enum IntervensionSideType {
  ONE_SIDE_ONLY = 'oneSideOnly',
  CAN_BE_BOTH_SIDE = 'canBeBothSide'
}
/* eslint-enable */

export const supportedSurgeryCodes = new Map([
  ['174', IntervensionSideType.ONE_SIDE_ONLY],
  ['206', IntervensionSideType.ONE_SIDE_ONLY],
  ['214', IntervensionSideType.ONE_SIDE_ONLY],
  ['486', IntervensionSideType.ONE_SIDE_ONLY],
  ['513', IntervensionSideType.ONE_SIDE_ONLY],
  ['514', IntervensionSideType.ONE_SIDE_ONLY],
  ['623', IntervensionSideType.ONE_SIDE_ONLY],
  ['640', IntervensionSideType.ONE_SIDE_ONLY],
  ['641', IntervensionSideType.ONE_SIDE_ONLY],
  ['727', IntervensionSideType.ONE_SIDE_ONLY],
  ['728', IntervensionSideType.ONE_SIDE_ONLY],
  ['744', IntervensionSideType.ONE_SIDE_ONLY],
  ['784', IntervensionSideType.ONE_SIDE_ONLY],
  ['785', IntervensionSideType.ONE_SIDE_ONLY],
  ['847', IntervensionSideType.ONE_SIDE_ONLY],
  ['848', IntervensionSideType.ONE_SIDE_ONLY],
  ['849', IntervensionSideType.ONE_SIDE_ONLY],
  ['850', IntervensionSideType.ONE_SIDE_ONLY],
  ['851', IntervensionSideType.ONE_SIDE_ONLY],
  ['852', IntervensionSideType.ONE_SIDE_ONLY],
  ['853', IntervensionSideType.ONE_SIDE_ONLY],
  ['855', IntervensionSideType.ONE_SIDE_ONLY],
  ['1011', IntervensionSideType.ONE_SIDE_ONLY],
  ['1012', IntervensionSideType.ONE_SIDE_ONLY],
  ['1021', IntervensionSideType.ONE_SIDE_ONLY],
  ['1029', IntervensionSideType.ONE_SIDE_ONLY],
  ['1037', IntervensionSideType.ONE_SIDE_ONLY],
  ['1038', IntervensionSideType.ONE_SIDE_ONLY],
  ['1039', IntervensionSideType.ONE_SIDE_ONLY],
  ['1040', IntervensionSideType.ONE_SIDE_ONLY],
  ['1052', IntervensionSideType.ONE_SIDE_ONLY],
  ['1053', IntervensionSideType.ONE_SIDE_ONLY],
  ['1054', IntervensionSideType.ONE_SIDE_ONLY],
  ['1055', IntervensionSideType.ONE_SIDE_ONLY],
  ['1176', IntervensionSideType.ONE_SIDE_ONLY],
  ['1201', IntervensionSideType.ONE_SIDE_ONLY],
  ['1205', IntervensionSideType.ONE_SIDE_ONLY],
  ['1228', IntervensionSideType.ONE_SIDE_ONLY],
  ['1230', IntervensionSideType.ONE_SIDE_ONLY],
  ['1231', IntervensionSideType.ONE_SIDE_ONLY],
  ['1232', IntervensionSideType.ONE_SIDE_ONLY],
  ['1233', IntervensionSideType.ONE_SIDE_ONLY],
  ['1235', IntervensionSideType.ONE_SIDE_ONLY],
  ['1250', IntervensionSideType.ONE_SIDE_ONLY],
  ['1251', IntervensionSideType.ONE_SIDE_ONLY],
  ['1252', IntervensionSideType.ONE_SIDE_ONLY],
  ['1253', IntervensionSideType.ONE_SIDE_ONLY],
  ['1254', IntervensionSideType.ONE_SIDE_ONLY],
  ['1255', IntervensionSideType.ONE_SIDE_ONLY],
  ['1329', IntervensionSideType.ONE_SIDE_ONLY],
  ['1386', IntervensionSideType.ONE_SIDE_ONLY],
  ['1388', IntervensionSideType.ONE_SIDE_ONLY],
  ['1401', IntervensionSideType.ONE_SIDE_ONLY],
  ['1402', IntervensionSideType.ONE_SIDE_ONLY],
  ['1403', IntervensionSideType.ONE_SIDE_ONLY],
  ['1407', IntervensionSideType.ONE_SIDE_ONLY],
  ['1408', IntervensionSideType.ONE_SIDE_ONLY],
  ['1409', IntervensionSideType.ONE_SIDE_ONLY],
  ['1410', IntervensionSideType.ONE_SIDE_ONLY],
  ['1419', IntervensionSideType.ONE_SIDE_ONLY],
  ['1434', IntervensionSideType.ONE_SIDE_ONLY],
  ['1435', IntervensionSideType.ONE_SIDE_ONLY],
  ['1436', IntervensionSideType.ONE_SIDE_ONLY],
  ['1463', IntervensionSideType.ONE_SIDE_ONLY],
  ['1464', IntervensionSideType.ONE_SIDE_ONLY],
  ['1465', IntervensionSideType.ONE_SIDE_ONLY],
  ['2006', IntervensionSideType.ONE_SIDE_ONLY],
  ['2011', IntervensionSideType.ONE_SIDE_ONLY],
  ['2013', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2020', IntervensionSideType.ONE_SIDE_ONLY],
  ['2021', IntervensionSideType.ONE_SIDE_ONLY],
  ['2030', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2033', IntervensionSideType.ONE_SIDE_ONLY],
  ['2034', IntervensionSideType.ONE_SIDE_ONLY],
  ['2035', IntervensionSideType.ONE_SIDE_ONLY],
  ['2037', IntervensionSideType.ONE_SIDE_ONLY],
  ['2038', IntervensionSideType.ONE_SIDE_ONLY],
  ['2039', IntervensionSideType.ONE_SIDE_ONLY],
  ['2040', IntervensionSideType.ONE_SIDE_ONLY],
  ['2041', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2042', IntervensionSideType.ONE_SIDE_ONLY],
  ['2046', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2047', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2049', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2050', IntervensionSideType.ONE_SIDE_ONLY],
  ['2054', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2055', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2056', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2059', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2060', IntervensionSideType.ONE_SIDE_ONLY],
  ['2061', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2067', IntervensionSideType.ONE_SIDE_ONLY],
  ['2068', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2069', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2070', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2074', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2079', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2081', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2084', IntervensionSideType.ONE_SIDE_ONLY],
  ['2085', IntervensionSideType.ONE_SIDE_ONLY],
  ['2086', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2091', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2092', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2093', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2094', IntervensionSideType.ONE_SIDE_ONLY],
  ['2095', IntervensionSideType.ONE_SIDE_ONLY],
  ['2115', IntervensionSideType.ONE_SIDE_ONLY],
  ['2120', IntervensionSideType.ONE_SIDE_ONLY],
  ['2122', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2125', IntervensionSideType.ONE_SIDE_ONLY],
  ['2126', IntervensionSideType.ONE_SIDE_ONLY],
  ['2138', IntervensionSideType.ONE_SIDE_ONLY],
  ['2144', IntervensionSideType.ONE_SIDE_ONLY],
  ['2145', IntervensionSideType.ONE_SIDE_ONLY],
  ['2158', IntervensionSideType.ONE_SIDE_ONLY],
  ['2160', IntervensionSideType.ONE_SIDE_ONLY],
  ['2161', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2165', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2174', IntervensionSideType.ONE_SIDE_ONLY],
  ['2175', IntervensionSideType.ONE_SIDE_ONLY],
  ['2176', IntervensionSideType.ONE_SIDE_ONLY],
  ['2177', IntervensionSideType.ONE_SIDE_ONLY],
  ['2178', IntervensionSideType.ONE_SIDE_ONLY],
  ['2179', IntervensionSideType.ONE_SIDE_ONLY],
  ['2193', IntervensionSideType.ONE_SIDE_ONLY],
  ['2194', IntervensionSideType.ONE_SIDE_ONLY],
  ['2195', IntervensionSideType.ONE_SIDE_ONLY],
  ['2198', IntervensionSideType.ONE_SIDE_ONLY],
  ['2199', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2219', IntervensionSideType.ONE_SIDE_ONLY],
  ['2220', IntervensionSideType.ONE_SIDE_ONLY],
  ['2222', IntervensionSideType.ONE_SIDE_ONLY],
  ['2227', IntervensionSideType.ONE_SIDE_ONLY],
  ['2230', IntervensionSideType.ONE_SIDE_ONLY],
  ['2231', IntervensionSideType.ONE_SIDE_ONLY],
  ['2232', IntervensionSideType.ONE_SIDE_ONLY],
  ['2234', IntervensionSideType.ONE_SIDE_ONLY],
  ['2237', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2238', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2240', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2241', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2242', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2244', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2245', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2246', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2247', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2251', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2252', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2253', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2254', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2259', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2260', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2264', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2267', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2271', IntervensionSideType.ONE_SIDE_ONLY],
  ['2273', IntervensionSideType.ONE_SIDE_ONLY],
  ['2275', IntervensionSideType.ONE_SIDE_ONLY],
  ['2276', IntervensionSideType.ONE_SIDE_ONLY],
  ['2278', IntervensionSideType.ONE_SIDE_ONLY],
  ['2279', IntervensionSideType.ONE_SIDE_ONLY],
  ['2280', IntervensionSideType.ONE_SIDE_ONLY],
  ['2281', IntervensionSideType.ONE_SIDE_ONLY],
  ['2282', IntervensionSideType.ONE_SIDE_ONLY],
  ['2285', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2287', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2291', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2292', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2294', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2295', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2296', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2299', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2302', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2304', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2305', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2306', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2307', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2309', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2314', IntervensionSideType.ONE_SIDE_ONLY],
  ['2315', IntervensionSideType.ONE_SIDE_ONLY],
  ['2316', IntervensionSideType.ONE_SIDE_ONLY],
  ['2317', IntervensionSideType.ONE_SIDE_ONLY],
  ['2318', IntervensionSideType.ONE_SIDE_ONLY],
  ['2319', IntervensionSideType.ONE_SIDE_ONLY],
  ['2320', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2321', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2333', IntervensionSideType.ONE_SIDE_ONLY],
  ['2344', IntervensionSideType.ONE_SIDE_ONLY],
  ['2346', IntervensionSideType.ONE_SIDE_ONLY],
  ['2349', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2358', IntervensionSideType.ONE_SIDE_ONLY],
  ['2359', IntervensionSideType.ONE_SIDE_ONLY],
  ['2360', IntervensionSideType.ONE_SIDE_ONLY],
  ['2363', IntervensionSideType.ONE_SIDE_ONLY],
  ['2365', IntervensionSideType.ONE_SIDE_ONLY],
  ['2370', IntervensionSideType.ONE_SIDE_ONLY],
  ['2372', IntervensionSideType.ONE_SIDE_ONLY],
  ['2375', IntervensionSideType.ONE_SIDE_ONLY],
  ['2376', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2377', IntervensionSideType.ONE_SIDE_ONLY],
  ['2378', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2379', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2382', IntervensionSideType.ONE_SIDE_ONLY],
  ['2383', IntervensionSideType.ONE_SIDE_ONLY],
  ['2386', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2387', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2388', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2398', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2403', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2404', IntervensionSideType.ONE_SIDE_ONLY],
  ['2405', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2406', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2407', IntervensionSideType.ONE_SIDE_ONLY],
  ['2408', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2409', IntervensionSideType.ONE_SIDE_ONLY],
  ['2410', IntervensionSideType.ONE_SIDE_ONLY],
  ['2412', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2413', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2414', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2419', IntervensionSideType.ONE_SIDE_ONLY],
  ['2422', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2426', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2433', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2434', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2441', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2473', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2484', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2492', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2498', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2500', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2501', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2536', IntervensionSideType.ONE_SIDE_ONLY],
  ['2537', IntervensionSideType.ONE_SIDE_ONLY],
  ['2543', IntervensionSideType.ONE_SIDE_ONLY],
  ['2544', IntervensionSideType.ONE_SIDE_ONLY],
  ['2546', IntervensionSideType.ONE_SIDE_ONLY],
  ['2547', IntervensionSideType.ONE_SIDE_ONLY],
  ['2548', IntervensionSideType.ONE_SIDE_ONLY],
  ['2549', IntervensionSideType.ONE_SIDE_ONLY],
  ['2550', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2551', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2552', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2554', IntervensionSideType.ONE_SIDE_ONLY],
  ['2555', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2556', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2558', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2560', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2561', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2562', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2563', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2565', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2566', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2568', IntervensionSideType.ONE_SIDE_ONLY],
  ['2573', IntervensionSideType.ONE_SIDE_ONLY],
  ['2575', IntervensionSideType.ONE_SIDE_ONLY],
  ['2576', IntervensionSideType.ONE_SIDE_ONLY],
  ['2577', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2596', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2597', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2598', IntervensionSideType.ONE_SIDE_ONLY],
  ['2605', IntervensionSideType.ONE_SIDE_ONLY],
  ['2606', IntervensionSideType.ONE_SIDE_ONLY],
  ['2608', IntervensionSideType.ONE_SIDE_ONLY],
  ['2609', IntervensionSideType.ONE_SIDE_ONLY],
  ['2610', IntervensionSideType.ONE_SIDE_ONLY],
  ['2614', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2619', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2625', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2629', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2630', IntervensionSideType.ONE_SIDE_ONLY],
  ['2631', IntervensionSideType.ONE_SIDE_ONLY],
  ['2632', IntervensionSideType.ONE_SIDE_ONLY],
  ['2633', IntervensionSideType.ONE_SIDE_ONLY],
  ['2634', IntervensionSideType.ONE_SIDE_ONLY],
  ['2635', IntervensionSideType.ONE_SIDE_ONLY],
  ['2640', IntervensionSideType.ONE_SIDE_ONLY],
  ['2650', IntervensionSideType.ONE_SIDE_ONLY],
  ['2651', IntervensionSideType.ONE_SIDE_ONLY],
  ['2652', IntervensionSideType.ONE_SIDE_ONLY],
  ['2655', IntervensionSideType.ONE_SIDE_ONLY],
  ['2657', IntervensionSideType.ONE_SIDE_ONLY],
  ['2658', IntervensionSideType.ONE_SIDE_ONLY],
  ['2659', IntervensionSideType.ONE_SIDE_ONLY],
  ['2662', IntervensionSideType.ONE_SIDE_ONLY],
  ['2673', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2674', IntervensionSideType.ONE_SIDE_ONLY],
  ['2680', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2687', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2688', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2689', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2690', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2691', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2694', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2696', IntervensionSideType.ONE_SIDE_ONLY],
  ['2698', IntervensionSideType.ONE_SIDE_ONLY],
  ['2699', IntervensionSideType.ONE_SIDE_ONLY],
  ['2700', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2704', IntervensionSideType.ONE_SIDE_ONLY],
  ['2706', IntervensionSideType.ONE_SIDE_ONLY],
  ['2708', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2710', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2711', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2713', IntervensionSideType.ONE_SIDE_ONLY],
  ['2714', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2716', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2717', IntervensionSideType.ONE_SIDE_ONLY],
  ['2718', IntervensionSideType.ONE_SIDE_ONLY],
  ['2719', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2720', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2721', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2722', IntervensionSideType.ONE_SIDE_ONLY],
  ['2723', IntervensionSideType.ONE_SIDE_ONLY],
  ['2724', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2725', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2726', IntervensionSideType.ONE_SIDE_ONLY],
  ['2727', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2729', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2730', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2731', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2734', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2737', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2741', IntervensionSideType.ONE_SIDE_ONLY],
  ['2742', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2743', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2744', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2746', IntervensionSideType.ONE_SIDE_ONLY],
  ['2747', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2748', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2749', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2750', IntervensionSideType.ONE_SIDE_ONLY],
  ['2757', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2778', IntervensionSideType.ONE_SIDE_ONLY],
  ['2779', IntervensionSideType.ONE_SIDE_ONLY],
  ['2780', IntervensionSideType.ONE_SIDE_ONLY],
  ['2781', IntervensionSideType.ONE_SIDE_ONLY],
  ['2783', IntervensionSideType.ONE_SIDE_ONLY],
  ['2784', IntervensionSideType.ONE_SIDE_ONLY],
  ['2785', IntervensionSideType.ONE_SIDE_ONLY],
  ['2786', IntervensionSideType.ONE_SIDE_ONLY],
  ['2787', IntervensionSideType.ONE_SIDE_ONLY],
  ['2788', IntervensionSideType.ONE_SIDE_ONLY],
  ['2789', IntervensionSideType.ONE_SIDE_ONLY],
  ['2790', IntervensionSideType.ONE_SIDE_ONLY],
  ['2791', IntervensionSideType.ONE_SIDE_ONLY],
  ['2792', IntervensionSideType.ONE_SIDE_ONLY],
  ['2793', IntervensionSideType.ONE_SIDE_ONLY],
  ['2794', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2795', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2796', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2797', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2798', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2799', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2801', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2802', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2803', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2808', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2811', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2813', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2816', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2822', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2833', IntervensionSideType.ONE_SIDE_ONLY],
  ['2834', IntervensionSideType.ONE_SIDE_ONLY],
  ['2835', IntervensionSideType.ONE_SIDE_ONLY],
  ['2836', IntervensionSideType.ONE_SIDE_ONLY],
  ['2839', IntervensionSideType.ONE_SIDE_ONLY],
  ['2840', IntervensionSideType.ONE_SIDE_ONLY],
  ['2841', IntervensionSideType.ONE_SIDE_ONLY],
  ['2849', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2864', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2865', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2866', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2867', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2868', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2869', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2870', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2871', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2873', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2874', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2880', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2883', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2884', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2885', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2889', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2892', IntervensionSideType.ONE_SIDE_ONLY],
  ['2894', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2895', IntervensionSideType.ONE_SIDE_ONLY],
  ['2897', IntervensionSideType.ONE_SIDE_ONLY],
  ['2898', IntervensionSideType.ONE_SIDE_ONLY],
  ['2904', IntervensionSideType.ONE_SIDE_ONLY],
  ['2905', IntervensionSideType.ONE_SIDE_ONLY],
  ['2906', IntervensionSideType.ONE_SIDE_ONLY],
  ['2907', IntervensionSideType.ONE_SIDE_ONLY],
  ['2912', IntervensionSideType.ONE_SIDE_ONLY],
  ['2913', IntervensionSideType.ONE_SIDE_ONLY],
  ['2914', IntervensionSideType.ONE_SIDE_ONLY],
  ['2921', IntervensionSideType.ONE_SIDE_ONLY],
  ['2925', IntervensionSideType.ONE_SIDE_ONLY],
  ['2926', IntervensionSideType.ONE_SIDE_ONLY],
  ['2927', IntervensionSideType.ONE_SIDE_ONLY],
  ['2928', IntervensionSideType.ONE_SIDE_ONLY],
  ['2929', IntervensionSideType.ONE_SIDE_ONLY],
  ['2930', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2932', IntervensionSideType.ONE_SIDE_ONLY],
  ['2934', IntervensionSideType.ONE_SIDE_ONLY],
  ['2936', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2937', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2938', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2939', IntervensionSideType.ONE_SIDE_ONLY],
  ['2940', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2943', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2944', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2946', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2948', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2949', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2950', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2951', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2952', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2953', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2954', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2955', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2956', IntervensionSideType.ONE_SIDE_ONLY],
  ['2958', IntervensionSideType.ONE_SIDE_ONLY],
  ['2960', IntervensionSideType.ONE_SIDE_ONLY],
  ['2965', IntervensionSideType.ONE_SIDE_ONLY],
  ['2966', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['2973', IntervensionSideType.ONE_SIDE_ONLY],
  ['2974', IntervensionSideType.ONE_SIDE_ONLY],
  ['2975', IntervensionSideType.ONE_SIDE_ONLY],
  ['2976', IntervensionSideType.ONE_SIDE_ONLY],
  ['2977', IntervensionSideType.ONE_SIDE_ONLY],
  ['2983', IntervensionSideType.ONE_SIDE_ONLY],
  ['2984', IntervensionSideType.ONE_SIDE_ONLY],
  ['2985', IntervensionSideType.ONE_SIDE_ONLY],
  ['2987', IntervensionSideType.ONE_SIDE_ONLY],
  ['2989', IntervensionSideType.ONE_SIDE_ONLY],
  ['2990', IntervensionSideType.ONE_SIDE_ONLY],
  ['2991', IntervensionSideType.ONE_SIDE_ONLY],
  ['2992', IntervensionSideType.ONE_SIDE_ONLY],
  ['2993', IntervensionSideType.ONE_SIDE_ONLY],
  ['2994', IntervensionSideType.ONE_SIDE_ONLY],
  ['2995', IntervensionSideType.ONE_SIDE_ONLY],
  ['2996', IntervensionSideType.ONE_SIDE_ONLY],
  ['2998', IntervensionSideType.ONE_SIDE_ONLY],
  ['3004', IntervensionSideType.ONE_SIDE_ONLY],
  ['3313', IntervensionSideType.ONE_SIDE_ONLY],
  ['3804', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['3806', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['4786', IntervensionSideType.ONE_SIDE_ONLY],
  ['4787', IntervensionSideType.ONE_SIDE_ONLY],
  ['4788', IntervensionSideType.ONE_SIDE_ONLY],
  ['5316', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['5320', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['5455', IntervensionSideType.ONE_SIDE_ONLY],
  ['6003', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6005', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6006', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6007', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6008', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6011', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6012', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6029', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6039', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6080', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6081', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6084', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6089', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6090', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6091', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6092', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6100', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6101', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6122', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6125', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6199', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6200', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6204', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6213', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6214', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6217', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6221', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6222', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6223', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6245', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6257', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6285', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6309', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6320', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6322', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6325', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6327', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6388', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6389', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6390', IntervensionSideType.ONE_SIDE_ONLY],
  ['6391', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['6394', IntervensionSideType.ONE_SIDE_ONLY],
  ['6804', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7002', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7007', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7008', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7022', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7035', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7036', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7037', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7045', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7046', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7048', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7051', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7052', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7053', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7054', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7056', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7057', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7058', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7059', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7060', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7061', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7063', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7065', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7068', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7069', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7071', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7072', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7074', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7075', IntervensionSideType.ONE_SIDE_ONLY],
  ['7076', IntervensionSideType.ONE_SIDE_ONLY],
  ['7077', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7078', IntervensionSideType.ONE_SIDE_ONLY],
  ['7079', IntervensionSideType.ONE_SIDE_ONLY],
  ['7080', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7081', IntervensionSideType.ONE_SIDE_ONLY],
  ['7083', IntervensionSideType.ONE_SIDE_ONLY],
  ['7084', IntervensionSideType.ONE_SIDE_ONLY],
  ['7085', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7086', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7087', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7088', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7089', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7090', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7091', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7092', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7093', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7094', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7095', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7096', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7097', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7098', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7099', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7100', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7104', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7105', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7106', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7106', IntervensionSideType.ONE_SIDE_ONLY],
  ['7107', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7108', IntervensionSideType.ONE_SIDE_ONLY],
  ['7109', IntervensionSideType.ONE_SIDE_ONLY],
  ['7115', IntervensionSideType.ONE_SIDE_ONLY],
  ['7122', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7123', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7127', IntervensionSideType.ONE_SIDE_ONLY],
  ['7133', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7134', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7136', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7137', IntervensionSideType.ONE_SIDE_ONLY],
  ['7137', IntervensionSideType.ONE_SIDE_ONLY],
  ['7140', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7141', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7143', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7144', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7145', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7146', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7147', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7148', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7149', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7151', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7152', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7153', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7157', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7159', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7167', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7168', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7169', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7170', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7171', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7173', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7174', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7175', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7179', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7180', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7184', IntervensionSideType.ONE_SIDE_ONLY],
  ['7185', IntervensionSideType.ONE_SIDE_ONLY],
  ['7187', IntervensionSideType.ONE_SIDE_ONLY],
  ['7192', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7193', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7194', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7196', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7197', IntervensionSideType.ONE_SIDE_ONLY],
  ['7198', IntervensionSideType.ONE_SIDE_ONLY],
  ['7199', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7204', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7210', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7211', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7212', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7213', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7222', IntervensionSideType.ONE_SIDE_ONLY],
  ['7223', IntervensionSideType.ONE_SIDE_ONLY],
  ['7227', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7228', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7229', IntervensionSideType.ONE_SIDE_ONLY],
  ['7230', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7231', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7232', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7233', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7235', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7236', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7237', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7238', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7241', IntervensionSideType.ONE_SIDE_ONLY],
  ['7242', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7243', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7244', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7248', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7249', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7250', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7251', IntervensionSideType.ONE_SIDE_ONLY],
  ['7252', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7253', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7255', IntervensionSideType.ONE_SIDE_ONLY],
  ['7256', IntervensionSideType.ONE_SIDE_ONLY],
  ['7257', IntervensionSideType.ONE_SIDE_ONLY],
  ['7258', IntervensionSideType.ONE_SIDE_ONLY],
  ['7259', IntervensionSideType.ONE_SIDE_ONLY],
  ['7260', IntervensionSideType.ONE_SIDE_ONLY],
  ['7261', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7263', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7264', IntervensionSideType.ONE_SIDE_ONLY],
  ['7266', IntervensionSideType.ONE_SIDE_ONLY],
  ['7267', IntervensionSideType.ONE_SIDE_ONLY],
  ['7268', IntervensionSideType.ONE_SIDE_ONLY],
  ['7269', IntervensionSideType.ONE_SIDE_ONLY],
  ['7270', IntervensionSideType.ONE_SIDE_ONLY],
  ['7272', IntervensionSideType.ONE_SIDE_ONLY],
  ['7279', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7281', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7282', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7283', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7284', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7287', IntervensionSideType.ONE_SIDE_ONLY],
  ['7288', IntervensionSideType.ONE_SIDE_ONLY],
  ['7289', IntervensionSideType.ONE_SIDE_ONLY],
  ['7292', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7298', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7299', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7300', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7302', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7303', IntervensionSideType.ONE_SIDE_ONLY],
  ['7306', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7308', IntervensionSideType.ONE_SIDE_ONLY],
  ['7310', IntervensionSideType.ONE_SIDE_ONLY],
  ['7311', IntervensionSideType.ONE_SIDE_ONLY],
  ['7313', IntervensionSideType.ONE_SIDE_ONLY],
  ['7314', IntervensionSideType.ONE_SIDE_ONLY],
  ['7316', IntervensionSideType.ONE_SIDE_ONLY],
  ['7319', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7330', IntervensionSideType.ONE_SIDE_ONLY],
  ['7330', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7331', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7336', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7337', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7339', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7356', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7361', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7362', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7364', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7368', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7370', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7372', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7376', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7377', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7378', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7379', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7381', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7385', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7386', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7387', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7390', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7392', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7394', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7395', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7396', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7399', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7402', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7403', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7404', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7405', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7406', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7407', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7408', IntervensionSideType.ONE_SIDE_ONLY],
  ['7409', IntervensionSideType.ONE_SIDE_ONLY],
  ['7411', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7412', IntervensionSideType.ONE_SIDE_ONLY],
  ['7413', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7414', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7415', IntervensionSideType.ONE_SIDE_ONLY],
  ['7416', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7417', IntervensionSideType.ONE_SIDE_ONLY],
  ['7418', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7419', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7420', IntervensionSideType.ONE_SIDE_ONLY],
  ['7421', IntervensionSideType.ONE_SIDE_ONLY],
  ['7424', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7427', IntervensionSideType.ONE_SIDE_ONLY],
  ['7430', IntervensionSideType.ONE_SIDE_ONLY],
  ['7433', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7435', IntervensionSideType.ONE_SIDE_ONLY],
  ['7436', IntervensionSideType.ONE_SIDE_ONLY],
  ['7437', IntervensionSideType.ONE_SIDE_ONLY],
  ['7438', IntervensionSideType.ONE_SIDE_ONLY],
  ['7439', IntervensionSideType.ONE_SIDE_ONLY],
  ['7442', IntervensionSideType.ONE_SIDE_ONLY],
  ['7443', IntervensionSideType.ONE_SIDE_ONLY],
  ['7445', IntervensionSideType.ONE_SIDE_ONLY],
  ['7446', IntervensionSideType.ONE_SIDE_ONLY],
  ['7449', IntervensionSideType.ONE_SIDE_ONLY],
  ['7450', IntervensionSideType.ONE_SIDE_ONLY],
  ['7451', IntervensionSideType.ONE_SIDE_ONLY],
  ['7452', IntervensionSideType.ONE_SIDE_ONLY],
  ['7453', IntervensionSideType.ONE_SIDE_ONLY],
  ['7456', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7457', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7458', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7459', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7460', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7461', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7461', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7462', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7463', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7464', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7465', IntervensionSideType.ONE_SIDE_ONLY],
  ['7466', IntervensionSideType.ONE_SIDE_ONLY],
  ['7480', IntervensionSideType.ONE_SIDE_ONLY],
  ['7481', IntervensionSideType.ONE_SIDE_ONLY],
  ['7488', IntervensionSideType.ONE_SIDE_ONLY],
  ['7489', IntervensionSideType.ONE_SIDE_ONLY],
  ['7491', IntervensionSideType.ONE_SIDE_ONLY],
  ['7494', IntervensionSideType.ONE_SIDE_ONLY],
  ['7495', IntervensionSideType.ONE_SIDE_ONLY],
  ['7567', IntervensionSideType.ONE_SIDE_ONLY],
  ['7800', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7801', IntervensionSideType.ONE_SIDE_ONLY],
  ['7801', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7802', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7802', IntervensionSideType.ONE_SIDE_ONLY],
  ['7803', IntervensionSideType.ONE_SIDE_ONLY],
  ['7804', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7808', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7809', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7810', IntervensionSideType.ONE_SIDE_ONLY],
  ['7812', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7813', IntervensionSideType.ONE_SIDE_ONLY],
  ['7814', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7814', IntervensionSideType.ONE_SIDE_ONLY],
  ['7816', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7818', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7819', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7820', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['7821', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9500', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9501', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9502', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9505', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9506', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9507', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9508', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9509', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9510', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9511', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9512', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9513', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9517', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9524', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9528', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9530', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9531', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9532', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9539', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9540', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9541', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9542', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9548', IntervensionSideType.ONE_SIDE_ONLY],
  ['9549', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9561', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9562', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9563', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9564', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9565', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9581', IntervensionSideType.ONE_SIDE_ONLY],
  ['9589', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9590', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9591', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9592', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9593', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9595', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9596', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['9598', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18012', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18013', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18014', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18015', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18017', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18018', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18019', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18020', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18021', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18022', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18023', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18024', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18025', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18026', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18027', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18028', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18029', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18030', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18031', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18032', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18033', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18035', IntervensionSideType.ONE_SIDE_ONLY],
  ['18036', IntervensionSideType.ONE_SIDE_ONLY],
  ['18037', IntervensionSideType.ONE_SIDE_ONLY],
  ['18038', IntervensionSideType.ONE_SIDE_ONLY],
  ['18039', IntervensionSideType.ONE_SIDE_ONLY],
  ['18040', IntervensionSideType.ONE_SIDE_ONLY],
  ['18041', IntervensionSideType.ONE_SIDE_ONLY],
  ['18042', IntervensionSideType.ONE_SIDE_ONLY],
  ['18043', IntervensionSideType.ONE_SIDE_ONLY],
  ['18044', IntervensionSideType.ONE_SIDE_ONLY],
  ['18045', IntervensionSideType.ONE_SIDE_ONLY],
  ['18046', IntervensionSideType.ONE_SIDE_ONLY],
  ['18047', IntervensionSideType.ONE_SIDE_ONLY],
  ['18048', IntervensionSideType.ONE_SIDE_ONLY],
  ['18049', IntervensionSideType.ONE_SIDE_ONLY],
  ['18050', IntervensionSideType.ONE_SIDE_ONLY],
  ['18051', IntervensionSideType.ONE_SIDE_ONLY],
  ['18052', IntervensionSideType.ONE_SIDE_ONLY],
  ['18053', IntervensionSideType.ONE_SIDE_ONLY],
  ['18054', IntervensionSideType.ONE_SIDE_ONLY],
  ['18055', IntervensionSideType.ONE_SIDE_ONLY],
  ['18064', IntervensionSideType.ONE_SIDE_ONLY],
  ['18065', IntervensionSideType.ONE_SIDE_ONLY],
  ['18066', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18067', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18068', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18069', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18070', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18071', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18072', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18073', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18074', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18075', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18076', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18077', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18078', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18089', IntervensionSideType.ONE_SIDE_ONLY],
  ['18090', IntervensionSideType.ONE_SIDE_ONLY],
  ['18091', IntervensionSideType.ONE_SIDE_ONLY],
  ['18092', IntervensionSideType.ONE_SIDE_ONLY],
  ['18093', IntervensionSideType.ONE_SIDE_ONLY],
  ['18096', IntervensionSideType.ONE_SIDE_ONLY],
  ['18097', IntervensionSideType.ONE_SIDE_ONLY],
  ['18098', IntervensionSideType.ONE_SIDE_ONLY],
  ['18099', IntervensionSideType.ONE_SIDE_ONLY],
  ['18100', IntervensionSideType.ONE_SIDE_ONLY],
  ['18101', IntervensionSideType.ONE_SIDE_ONLY],
  ['18102', IntervensionSideType.ONE_SIDE_ONLY],
  ['18103', IntervensionSideType.ONE_SIDE_ONLY],
  ['18105', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18106', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18107', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18108', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18109', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18111', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18112', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18115', IntervensionSideType.ONE_SIDE_ONLY],
  ['18116', IntervensionSideType.ONE_SIDE_ONLY],
  ['18127', IntervensionSideType.ONE_SIDE_ONLY],
  ['18136', IntervensionSideType.ONE_SIDE_ONLY],
  ['18137', IntervensionSideType.ONE_SIDE_ONLY],
  ['18138', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18139', IntervensionSideType.ONE_SIDE_ONLY],
  ['18140', IntervensionSideType.ONE_SIDE_ONLY],
  ['18141', IntervensionSideType.ONE_SIDE_ONLY],
  ['18142', IntervensionSideType.ONE_SIDE_ONLY],
  ['18143', IntervensionSideType.ONE_SIDE_ONLY],
  ['18144', IntervensionSideType.ONE_SIDE_ONLY],
  ['18145', IntervensionSideType.ONE_SIDE_ONLY],
  ['18146', IntervensionSideType.ONE_SIDE_ONLY],
  ['18147', IntervensionSideType.ONE_SIDE_ONLY],
  ['18148', IntervensionSideType.ONE_SIDE_ONLY],
  ['18151', IntervensionSideType.ONE_SIDE_ONLY],
  ['18152', IntervensionSideType.ONE_SIDE_ONLY],
  ['18153', IntervensionSideType.ONE_SIDE_ONLY],
  ['18154', IntervensionSideType.ONE_SIDE_ONLY],
  ['18155', IntervensionSideType.ONE_SIDE_ONLY],
  ['18156', IntervensionSideType.ONE_SIDE_ONLY],
  ['18157', IntervensionSideType.ONE_SIDE_ONLY],
  ['18158', IntervensionSideType.ONE_SIDE_ONLY],
  ['18159', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18160', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18161', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18162', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18163', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18164', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18165', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18166', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18167', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18168', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18169', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18176', IntervensionSideType.ONE_SIDE_ONLY],
  ['18177', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18178', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18179', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18180', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['18181', IntervensionSideType.CAN_BE_BOTH_SIDE],
  ['20029', IntervensionSideType.ONE_SIDE_ONLY],
  ['20065', IntervensionSideType.ONE_SIDE_ONLY],
  ['20066', IntervensionSideType.ONE_SIDE_ONLY],
  ['20067', IntervensionSideType.ONE_SIDE_ONLY],
  ['20068', IntervensionSideType.ONE_SIDE_ONLY],
  ['20069', IntervensionSideType.ONE_SIDE_ONLY],
  ['20070', IntervensionSideType.ONE_SIDE_ONLY],
  ['20071', IntervensionSideType.ONE_SIDE_ONLY],
  ['20217', IntervensionSideType.ONE_SIDE_ONLY],
  ['20219', IntervensionSideType.ONE_SIDE_ONLY],
  ['20220', IntervensionSideType.ONE_SIDE_ONLY],
  ['20221', IntervensionSideType.ONE_SIDE_ONLY],
  ['20222', IntervensionSideType.ONE_SIDE_ONLY],
  ['20223', IntervensionSideType.ONE_SIDE_ONLY]
]);
